import { GolfCourseOutlined, HomeOutlined, KeyOutlined, NewspaperOutlined, PersonOutline, ReceiptOutlined } from "@mui/icons-material"

const Navigations = [
	{
		label: 'Home',
		icon: <HomeOutlined />,
		path: '/',
	},
	{
		label: 'Bills',
		icon: <ReceiptOutlined />,
		path: '/bills',
	},
	{
		label: 'Handicap',
		icon: <GolfCourseOutlined />,
		path: '/handicap',
	},
	{
		label: 'News & Events',
		icon: <NewspaperOutlined />,
		path: '/news',
	},
	{
		label: 'Update Profile',
		icon: <PersonOutline />,
		path: '/update-profile',
	},
	{
		label: 'Change Password',
		icon: <KeyOutlined />,
		path: '/change-password',
	},
]

export default Navigations